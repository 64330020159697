import React, { useRef } from "react";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
  axiosInstance,
  convertFormData,
  multiPartConfig,
  toChecksumAddress,
  toastTime,
  xdcToEthAddress,
} from "../../helpers/constants";
import { toast } from "react-toastify";

const AddPool = () => {
  const poolLogo = useRef(null);
  const poolProspectus = useRef(null);
  const siteLogo = useRef(null);
  const PoolSchema = Yup.object().shape({
    name: Yup.string()
      .required("Pool name is required.")
      .max(50, "Pool name cannot be more than 50 characters."),
    description: Yup.string().required("Pool description is required."),
    // proxy_token_address: Yup.string().required(
    //   "Investment token address is required."
    // ),
    website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter valid URL"
      )
      .required("Pool website is required."),
    issuer_name: Yup.string()
      .required("Issuer name is required")
      .max(50, "Issuer Name cannot be more than 50 characters"),
    issuer_email: Yup.string()
      .email("Invalid email")
      .required("Issuer email is required"),
    asset_type: Yup.string().required("Pool asset type is required"),
    logo: Yup.mixed().required("Pool Logo required"),
    websiteLogo: Yup.mixed().required("Website Logo required"),
    pool_capacity: Yup.number()
      .integer()
      .moreThan(0, "Pool Capacity should be greater than 0")
      .required("Pool Capacity is required"),
    apy_rate: Yup.number()
      .moreThan(0, "APY Rate should be greater than 0")
      .required("APY Rate is required"),
    reward_rate: Yup.number().moreThan(
      -1,
      "Reward Token Rate should be greater than or equal to 0"
    ),
    tenure: Yup.number()
      .integer()
      .min(1, "Tenure should be greater than or equal to 1 month")
      .required("Tenure is required"),
    investmentTokenName: Yup.string().required(
      "Investment token name is required"
    ),
    investmentTokenSymbol: Yup.string().required(
      "Investment token symbol is required"
    ),
  });
  const formik = useFormik({
    validate: (values) => {
      const errors = {};
      if (values.logo) {
        if (poolLogo.current && poolLogo.current.files[0].size > 50000) {
          errors.logo = "File size greater than 50KB";
        }
      }
      if (values.websiteLogo) {
        if (siteLogo.current && siteLogo.current.files[0].size > 100000) {
          errors.websiteLogo = "File size greater than 100KB";
        }
      }
      if (values.prospectus) {
        if (
          poolProspectus.current &&
          poolProspectus.current.files[0].size > 2000000
        ) {
          errors.prospectus = "File size greater than 2MB";
        }
      }
      return errors;
    },
    initialValues: {
      name: "",
      logo: "",
      websiteLogo: "",
      description: "",
      issuer_name: "",
      issuer_email: "",
      website: "",
      asset_type: "Trade Finance",
      proxy_token_address: "",
      prospectus: "",
      pool_capacity: "",
      apy_rate: "",
      reward_rate: 0,
      tenure: "",
      settlement_cycle: "",
      investmentTokenName: "",
      investmentTokenSymbol: "Select",
    },
    validationSchema: PoolSchema,
    onSubmit: async (data, { resetForm }) => {
      try {
        const poolFile = poolLogo.current.files[0];
        data.logo = poolFile;
        const prospectusFile = poolProspectus.current.files[0];
        data.prospectus = prospectusFile;
        const siteLogoFile = siteLogo.current.files[0];
        data.websiteLogo = siteLogoFile;
        const formData = convertFormData(data);
        const response = await axiosInstance.post(
          "/add-pool",
          formData,
          multiPartConfig
        );
        if (response.data.statusCode === 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          });
          resetForm();
          formik.setFieldValue("logo", "");
          formik.setFieldValue("websiteLogo", "");
          formik.setFieldValue("prospectus", "");
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: toastTime,
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  });
  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    getFieldProps,
  } = formik;

  const symbolMap = {
    Select: { name: "", address: "" },
    FXD: {
      name: "Fathom Stable Coin",
      address: "xdc49d3f7543335cf38fa10889ccff10207e22110b5",
    },
    // USPLUS: { name: "US Plus", address: "xyz" },
  };

  return (
    <section className="section-sm section-layout-height section-padding-10-top pb-0">
      <div className="container bg-red custom-container">
        <div className="section-padding-120-top">
          <div className="row justify-content-center pb-4">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="stats-box px-5 py-4 bg-white">
                <FormikProvider value={formik}>
                  <form onSubmit={handleSubmit}>
                    <p className="fs-20 fw-7 lh-16 fc-dark mb-3">
                      Lending Pool Form
                    </p>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="name" className="form-label fw-7 fs-16">
                          Pool Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter name"
                          {...getFieldProps("name")}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.name}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="logo" className="form-label fw-7 fs-16">
                          Pool Logo
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          accept="image/png, image/jpg,  image/svg,image/jpeg"
                          id="logo"
                          ref={poolLogo}
                          placeholder="Enter pool logo"
                          {...getFieldProps("logo")}
                          error={Boolean(touched.logo && errors.logo)}
                          helperText={touched.logo && errors.logo}
                        />
                        {formik.touched.logo && formik.errors.logo ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.logo}*
                          </div>
                        ) : null}
                        <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                          <span
                            className=""
                            style={{
                              fontSize: "0.688rem",
                            }}
                          >
                            Maximum 50KB file size{" "}
                          </span>
                          <span
                            className=""
                            style={{
                              fontSize: "0.688rem",
                            }}
                          >
                            {" "}
                            Accepted File Type .jpg, .jpeg, .png & .svg only
                          </span>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <label
                          htmlFor="description"
                          className="form-label fw-7 fs-16"
                        >
                          Pool Description
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="description"
                          placeholder="Enter Pool Description...."
                          {...getFieldProps("description")}
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          helperText={touched.description && errors.description}
                        />
                        {formik.touched.description &&
                        formik.errors.description ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.description}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="websiteLogo"
                          className="form-label fw-7 fs-16"
                        >
                          Website Logo
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          accept="image/png, image/jpg,  image/svg,image/jpeg"
                          id="websiteLogo"
                          ref={siteLogo}
                          placeholder="Enter website pool logo"
                          {...getFieldProps("websiteLogo")}
                          error={Boolean(
                            touched.websiteLogo && errors.websiteLogo
                          )}
                          helperText={touched.websiteLogo && errors.websiteLogo}
                        />
                        {formik.touched.websiteLogo &&
                        formik.errors.websiteLogo ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.websiteLogo}*
                          </div>
                        ) : null}
                        <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                          <span
                            className=""
                            style={{
                              fontSize: "0.688rem",
                            }}
                          >
                            Maximum 100KB file size{" "}
                          </span>
                          <span
                            className=""
                            style={{
                              fontSize: "0.688rem",
                            }}
                          >
                            {" "}
                            Accepted File Type .jpg, .jpeg, .png & .svg only
                          </span>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="issuer_name"
                          className="form-label fw-7 fs-16"
                        >
                          Issuer Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="issuer_name"
                          placeholder="Enter Issuer Name"
                          {...getFieldProps("issuer_name")}
                        />
                        {formik.touched.issuer_name &&
                        formik.errors.issuer_name ? (
                          <div className="form-error">
                            {formik.errors.issuer_name}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="issuer_email"
                          className="form-label fw-7 fs-16"
                        >
                          Issuer Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="issuer_email"
                          placeholder="Enter Issuer Email"
                          {...getFieldProps("issuer_email")}
                        />
                        {formik.touched.issuer_email &&
                        formik.errors.issuer_email ? (
                          <div className="form-error">
                            {formik.errors.issuer_email}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="website"
                          className="form-label fw-7 fs-16"
                        >
                          Pool Website
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="website"
                          placeholder="Enter  Pool Website"
                          {...getFieldProps("website")}
                        />
                        {formik.touched.website && formik.errors.website ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.website}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="asset_type"
                          className="form-label fw-7 fs-16"
                        >
                          Pool Asset Type
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="asset_type"
                          placeholder="Enter Pool Asset Type"
                          {...getFieldProps("asset_type")}
                        />
                        {formik.touched.asset_type &&
                        formik.errors.asset_type ? (
                          <div className="form-error">
                            {formik.errors.asset_type}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="prospectus"
                          className="form-label fw-7 fs-16"
                        >
                          Prospectus
                        </label>
                        <input
                          type="file"
                          ref={poolProspectus}
                          className="form-control"
                          accept="application/pdf"
                          id="prospectus"
                          placeholder="Enter pool prospectus"
                          {...getFieldProps("prospectus")}
                          error={Boolean(
                            touched.prospectus && errors.prospectus
                          )}
                          helperText={touched.prospectus && errors.prospectus}
                        />
                        {formik.touched.prospectus &&
                        formik.errors.executive_summary ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.prospectus}*
                          </div>
                        ) : null}
                        <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                          <span
                            className=""
                            style={{
                              fontSize: "0.688rem",
                            }}
                          >
                            Maximum 2MB file size{" "}
                          </span>
                          <span
                            className=""
                            style={{
                              fontSize: "0.688rem",
                            }}
                          >
                            {" "}
                            Accepted File Type .pdf
                          </span>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="pool_capacity"
                          className="form-label fw-7 fs-16"
                        >
                          Pool Capacity
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="pool_capacity"
                          placeholder="Pool Capacity"
                          {...getFieldProps("pool_capacity")}
                          error={Boolean(
                            touched.pool_capacity && errors.pool_capacity
                          )}
                          helperText={
                            touched.pool_capacity && errors.pool_capacity
                          }
                        />
                        {formik.touched.pool_capacity &&
                        formik.errors.pool_capacity ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.pool_capacity}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="apy_rate"
                          className="form-label fw-7 fs-16"
                        >
                          APY Rate (%)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="apy_rate"
                          placeholder="APY Rate"
                          {...getFieldProps("apy_rate")}
                          error={Boolean(touched.apy_rate && errors.apy_rate)}
                          helperText={touched.apy_rate && errors.apy_rate}
                        />
                        {formik.touched.apy_rate && formik.errors.apy_rate ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.apy_rate}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="reward_rate"
                          className="form-label fw-7 fs-16"
                        >
                          Reward Token Rate (%)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="reward_rate"
                          placeholder="Reward Token Rate"
                          {...getFieldProps("reward_rate")}
                          error={Boolean(
                            touched.reward_rate && errors.reward_rate
                          )}
                          helperText={touched.reward_rate && errors.reward_rate}
                        />
                        {formik.touched.reward_rate &&
                        formik.errors.reward_rate ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.reward_rate}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="tenure"
                          className="form-label fw-7 fs-16"
                        >
                          Tenure (in months)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="tenure"
                          placeholder="Tenure"
                          {...getFieldProps("tenure")}
                          error={Boolean(touched.tenure && errors.tenure)}
                          helperText={touched.tenure && errors.tenure}
                        />
                        {formik.touched.tenure && formik.errors.tenure ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.tenure}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="settlement_cycle"
                          className="form-label fw-7 fs-16"
                        >
                          Settlement Cycle (in days)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="settlement_cycle"
                          placeholder="Settlement Cycle"
                          {...getFieldProps("settlement_cycle")}
                          error={Boolean(
                            touched.settlement_cycle && errors.settlement_cycle
                          )}
                          helperText={
                            touched.settlement_cycle && errors.settlement_cycle
                          }
                        />
                        {formik.touched.settlement_cycle &&
                        formik.errors.settlement_cycle ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.settlement_cycle}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="investmentTokenSymbol"
                          className="form-label fw-7 fs-16"
                        >
                          Investment Token Symbol
                        </label>
                        {/* <input
                          type="text"
                          className="form-control"
                          id="investmentTokenSymbol"
                          placeholder="Investment Token Symbol"
                          {...getFieldProps("investmentTokenSymbol")}
                          error={Boolean(
                            touched.investmentTokenSymbol &&
                              errors.investmentTokenSymbol
                          )}
                          helperText={
                            touched.investmentTokenSymbol &&
                            errors.investmentTokenSymbol
                          }
                        /> */}
                        <select
                          class="form-select "
                          className="form-control select-css"
                          id="investmentTokenSymbol"
                          placeholder="Select Investment Token Symbol"
                          type="string"
                          value={values.investmentTokenSymbol}
                          {...getFieldProps("investmentTokenSymbol")}
                          onChange={(event) => {
                            setFieldValue(
                              "investmentTokenSymbol",
                              event.target.value
                            );
                            setFieldValue(
                              "investmentTokenName",
                              symbolMap[event.target.value]["name"]
                            );
                            setFieldValue(
                              "proxy_token_address",
                              symbolMap[event.target.value]["address"]
                            );
                          }}
                        >
                          {Object.keys(symbolMap).map((opt) => (
                            <option key={opt} value={opt}>
                              {opt}
                            </option>
                          ))}
                        </select>
                        {formik.touched.investmentTokenSymbol &&
                        formik.errors.investmentTokenSymbol ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.investmentTokenSymbol}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="investmentTokenName"
                          className="form-label fw-7 fs-16"
                        >
                          Investment Token Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="investmentTokenName"
                          placeholder="Investment Token Name"
                          {...getFieldProps("investmentTokenName")}
                          error={Boolean(
                            touched.investmentTokenName &&
                              errors.investmentTokenName
                          )}
                          helperText={
                            touched.investmentTokenName &&
                            errors.investmentTokenName
                          }
                          value={
                            values.investmentTokenSymbol
                              ? symbolMap[values.investmentTokenSymbol]["name"]
                              : ""
                          }
                          disabled
                        />
                        {formik.touched.investmentTokenName &&
                        formik.errors.investmentTokenName ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.investmentTokenName}*
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label
                          htmlFor="proxy_token_address"
                          className="form-label fw-7 fs-16"
                        >
                          Investment Token Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="proxy_token_address"
                          placeholder="Investment Token Address"
                          {...getFieldProps("proxy_token_address")}
                          error={Boolean(
                            touched.proxy_token_address &&
                              errors.proxy_token_address
                          )}
                          helperText={
                            touched.proxy_token_address &&
                            errors.proxy_token_address
                          }
                          value={
                            values.investmentTokenSymbol
                              ? symbolMap[values.investmentTokenSymbol][
                                  "address"
                                ]
                              : ""
                          }
                          disabled
                        />
                        {formik.touched.proxy_token_address &&
                        formik.errors.proxy_token_address ? (
                          <div className="form-error" style={{ fontSize: 12 }}>
                            {formik.errors.proxy_token_address}*
                          </div>
                        ) : null}
                      </div>
                      {/* end */}
                    </div>
                    {/* Submit Button */}
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn submit-btn"
                        type="submit"
                        style={{ height: 40, fontSize: 16, fontWeight: 700 }}
                      >
                        <span
                          className="d-flex justify-content-between"
                          style={{ width: "auto" }}
                        >
                          {isSubmitting && (
                            <span
                              className="spinner-border spinner-border-sm mx-1"
                              style={{ marginTop: "0.3rem" }}
                            ></span>
                          )}{" "}
                          Submit Pool
                        </span>
                      </button>
                    </div>
                  </form>
                </FormikProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddPool;
