import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserLayout from "./components/user/UserLayout";
import Home from "./pages/user/Home";
import Disclaimer from "./pages/user/Disclaimer";
import AddPool from "./pages/user/AddPool";
import StakingGuide from "./pages/user/StakingGuide";
import Overview from "./pages/user/Overview";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer theme="colored" />
      <Routes>
        <Route
          path="/"
          element={
            <UserLayout>
              <Outlet />
            </UserLayout>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/add-pool" element={<AddPool />} />
          <Route path="/staking-guide" element={<StakingGuide />} />
          <Route path="/overview/:pool" element={<Overview />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
