import React from "react";
import Image1 from "../../assets/images/staking-guide/image1.png";
import Image2 from "../../assets/images/staking-guide/image2.png";
import Image3 from "../../assets/images/staking-guide/image3.png";
import Image4 from "../../assets/images/staking-guide/image4.png";
import Image5 from "../../assets/images/staking-guide/image5.png";
import Image6 from "../../assets/images/staking-guide/image6.png";
import { Link } from "react-router-dom";

const StakingGuide = () => {
  return (
    <section className="section-sm section-padding-140-top pb-0">
      <div className="container custom-container">
        <div className="row mt-1">
          <div className="col-lg-10 offset-lg-1 col-md-12">
            <div className="staking-guide">
              <h3 className="fs-24 fw-7 lh-28 fc-dark mb-3">
                Step-by-Step guide on How to stake your FXD tokens
              </h3>
              <ol>
                <li className="fs-16 lh-22">
                  <span className="fs-17 fw-7">
                    Visit{" "}
                    <a
                      href="https://tradefinex.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      TradeFinex
                    </a>
                  </span>
                  <br />
                  To participate in FXD staking on TradeFinex, please visit the
                  following URL:{" "}
                  <a
                    href="https://staking.tradefinex.org/staking"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://staking.tradefinex.org/staking
                  </a>
                  . On this page, you will find the option to stake your FXD
                  tokens. The minimum staking amount allowed is 5000 FXD, while
                  the maximum amount is capped at 25000 FXD. By staking within
                  this range, you can earn a 12% annual percentage yield (APY).
                  The staking period for this opportunity is set at 1 month.
                  Additionally, you can also check the Network status on the
                  page to view the pool details and other relevant information.
                  <br />
                  <img
                    src={Image1}
                    alt="image1"
                    className="img-fluid bg-white"
                  />
                </li>
                <li className="fs-16 lh-22">
                  <span className="fs-17 fw-7">Connect Wallet</span>
                  <br />
                  Upon visiting the TradeFinex staking page, you will be
                  provided with the option to connect your preferred wallet. You
                  can choose to connect using XDCPay, Metamask, or any other XDC
                  Network wallet that supports the wallet connect feature. This
                  flexibility allows you to select the wallet of your choice,
                  ensuring compatibility and ease of use during the staking
                  process.
                  <br />
                  <img
                    src={Image2}
                    alt="image2"
                    className="img-fluid bg-white"
                  />
                </li>
                <li className="fs-16 lh-22">
                  <span className="fs-17 fw-7">Approve FXD</span>
                  <br />
                  Once you have connected your preferred wallet on the
                  TradeFinex staking page, the next step is to approve the
                  specific amount of FXD tokens you intend to stake. This
                  approval process confirms your consent to stake the chosen
                  amount of FXD tokens within the staking platform.
                  <br />
                  <img
                    src={Image3}
                    alt="image3"
                    className="img-fluid bg-white"
                  />
                </li>
                <li className="fs-16 lh-22">
                  <span className="fs-17 fw-7">Stake FXD</span>
                  <br />
                  After successfully approving the FXD tokens, you can proceed
                  to stake them. This means you will now initiate the staking
                  process, where you lock and deposit the approved amount of FXD
                  tokens into the staking platform. By staking your tokens, you
                  become eligible to earn rewards. <br /> Note : FXD is an XDC
                  Network-based stablecoin, representing a stable-price
                  cryptocurrency issued and developed by the Fathom.fi DAO, a
                  decentralized autonomous organization. The value of FXD is
                  softly pegged to the U.S. dollar and is supported by an over
                  collateralized XDC Token, which is securely held in
                  smart-contract vaults during the creation of new FXD tokens.
                  Mint FXD from{" "}
                  <a
                    href="https://dapp.fathom.fi/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Fathom.fi
                  </a>
                  <br />
                  <img
                    src={Image4}
                    alt="image4"
                    className="img-fluid bg-white"
                  />
                </li>
                <li className="fs-16 lh-22">
                  <span className="fs-17 fw-7">Check Statement</span>
                  <br />
                  Once you have successfully staked the FXD tokens, you can
                  access the statement or summary that provides important
                  information regarding your staking. This statement will
                  include details such as the amount of FXD tokens staked, the
                  duration of the staking tenure, and the rewards earned from
                  your staking activity. It will also indicate the anticipated
                  end date of your staking period, giving you an idea of when
                  your staking will be completed. Checking this statement will
                  allow you to track and monitor the progress of your staking
                  investment.
                  <br />
                  <img
                    src={Image5}
                    alt="image5"
                    className="img-fluid bg-white"
                  />
                </li>
                <li className="fs-16 lh-21">
                  <span className="fs-17 fw-7">Statement after Completion</span>
                  <br />
                  Upon the completion of your 30-days staking period, the
                  statement will indicate that your staking has reached its
                  conclusion. At this point, you will receive your staked FXD
                  tokens back, along with any rewards earned during the staking
                  duration. This completion status in the statement confirms the
                  successful conclusion of your staking activity and signifies
                  that your staked tokens and rewards are ready for withdrawal
                  or further utilization.
                  <br />
                  <img
                    src={Image6}
                    alt="image6"
                    className="img-fluid bg-white"
                  />
                </li>
              </ol>
              <span className="mb-2 pb-2">
                Click here to <Link to="/add-pool">Create pool</Link> or{" "}
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdyQkwpYPAAUc5llJxk09ymMdjSSSjjiY3spwvRvCwfV08h2A/viewform"
                  rel="noreferrer"
                  target="_blank"
                >
                  Apply for Whitelist
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StakingGuide;
