import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="mx-2">
        <div className="row footer-wrap footer-gradient text-white">
          <div className="col-lg-4 col-md-4">
            Copyright © {new Date().getFullYear()}{" "}
            <a href="https://tradefinex.org" rel="noreferrer" target="_blank">
              TradeFinex
            </a>
            . All Rights Reserved.
          </div>
          <div className="col-md-8 col-lg-8">
            <div className="privacy-terms ">
              {/* <span className="disclaimer cap-label px-2 py-1 mr-1">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                <a
                  onClick={() => {
                    navigate('/disclaimer');
                  }}
                  rel="noreferrer"
                >
                  Disclaimer
                </a>
              </span>{' '}
              |{' '} */}
              <a
                href="https://www.tradefinex.org/publicv/termsCondition"
                target="_blank"
                rel="noreferrer"
              >
                Terms and Condition
              </a>{" "}
              |{" "}
              <a
                href="https://www.tradefinex.org/publicv/privacyPolicy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              |{" "}
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdyQkwpYPAAUc5llJxk09ymMdjSSSjjiY3spwvRvCwfV08h2A/viewform"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-envelope"></i> Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
