import React, { useEffect, useState } from "react";
import axios from "axios";
import chartImg from "../../assets/images/chart-img.png";
import XDCIcon from "../../assets/images/icons/xdc-icon.png";
import FXDIcon from "../../assets/images/icons/fxd-icon.jpg";
import { axiosInstance, numberToReadable } from "../../helpers/constants";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const TVLSection = () => {
  const [XDCTVL, setXDCTVL] = useState("");
  const [USDTVL, setUSDTVL] = useState("");
  const [FXDSupply, setFXDSupply] = useState("");
  const [chartData, setChartData] = useState([]);
  const [hoveredValue, setHoveredValue] = useState();

  useEffect(() => {
    const updateData = async () => {
      let data = await axiosInstance.get("/get-chart-data");
      setChartData(data.data.data);
      // let fetchURL =
      //   "https://xdc.network/api/token-holders?address=xdc951857744785e80e2de051c32ee7b25f9c458c42&hash=xdc9b4acefe2db986ca080dc01d137e6566dbe0aa3a";
      // let fetchData = await axios.get(fetchURL);
      // data = fetchData.data;
      // const value = data.items[0].quantity / 1e18;
      // setXDCTVL(numberToReadable(value));
      // const XDCPrice = data.items[0].tokenObj.priceUSD;
      // setUSDTVL(
      //   numberToReadable(parseFloat(parseFloat(value * XDCPrice).toFixed(0)))
      // );
      // fetchURL =
      //   "https://xdc.network/api/tokensinfo/xdc49d3f7543335cf38fa10889ccff10207e22110b5?type=totalsupply";
      // fetchData = await axios.get(fetchURL);
      // setFXDSupply(
      //   numberToReadable(parseFloat(parseFloat(fetchData.data).toFixed(0)))
      // );
      data = await axiosInstance.get("/get-tvls");
      setXDCTVL(numberToReadable(data.data.data.XDCTVL));
      setUSDTVL(numberToReadable(data.data.data.USDTVL));
      setFXDSupply(numberToReadable(data.data.data.FXDSupply));
    };
    updateData();
  }, []);
  return (
    <section className="section-sm section-padding-140-top pb-0">
      <div className="container custom-container">
        <div className="row">
          <div className="col-lg-9 offset-lg-2">
            <h3
              className="header-stats text-center"
              style={{ color: "black", fontWeight: 600 }}
            >
              TradeFinex is an open TradeFi protocol and marketplace for
              real-world asset pools.
            </h3>
          </div>
        </div>
        <div className="row mt-1 justify-content-center">
          <div className="col-sm-12 col-md-8 col-lg-7 col-xl-6">
            <div className="stats-box bg-white h100 p-3">
              <div
                className="media align-items-center tvl-chart-block d-flex justify-content-between"
                style={{ maxHeight: "30vh" }}
              >
                <div
                  className="chart col-xs-5  col-sm-6 col-md-5 col-lg-6 col-xl-5 max-width-100"
                  style={{ width: "14rem", height: "4rem" }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    {/* TradingView Widget BEGIN */}
                    {/* <div className="chart">
                      <img src={chartImg} alt="chart" />
                    </div> */}
                    {/* TradingView Widget END */}

                    <AreaChart
                      width={500}
                      height={400}
                      data={chartData}
                      onMouseMove={(item) => {
                        if (item.activePayload) {
                          setHoveredValue(item.activePayload[0].payload);
                        }
                      }}
                      onMouseLeave={() => {
                        setHoveredValue(undefined);
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="colorPoolValue"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="12%"
                            stopColor="#3366d6"
                            stopOpacity={0.7}
                          />
                          <stop
                            offset="45%"
                            stopColor="#7e6cfb"
                            stopOpacity={0.5}
                          />
                          <stop
                            offset="95%"
                            stopColor="#71a3f7"
                            stopOpacity={0.05}
                          />
                        </linearGradient>
                      </defs>
                      {/* <XAxis type="number" dataKey="date" /> */}
                      {/* <YAxis
                        type="number"
                        domain={[0, USDTVL]}
                        dataKey="USDTVL"
                      /> */}
                      <Area
                        type="monotone"
                        dataKey="USDTVL"
                        stroke="#3366d6"
                        strokeWidth={2}
                        fillOpacity={1}
                        fill="url(#colorPoolValue)"
                        name="Current Value Locked"
                      />
                      <Tooltip
                        content={
                          <>
                            <em>{hoveredValue ? hoveredValue.date : ""}</em>
                            <br />
                            <em>
                              USD TVL{" "}
                              {hoveredValue
                                ? numberToReadable(hoveredValue.USDTVL)
                                : 0}
                            </em>
                            <br />
                            <em>
                              XDC TVL{" "}
                              {hoveredValue
                                ? numberToReadable(hoveredValue.XDCTVL)
                                : 0}
                            </em>
                          </>
                        }
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <div className="media-body text-center tvl-gap-bet-col col-sm-6 col-md-5 col-lg-5 col-xl-5 mt-lg-0 mt-md-0 mt-2 ml-md-1.2">
                  <div className="d-flex align-items-baseline tvl-header no-wrap mb-0">
                    <div className="token-icon">
                      <img src={XDCIcon} alt="XDC" />
                    </div>
                    <div className="no-wrap">
                      <span className="fs-22">{XDCTVL}</span>
                      <span className="fs-14 fw-8">XDC</span>
                    </div>
                  </div>
                  <div className="" style={{ width: 190 }}>
                    <p className="fs-13 fc-dark fw-5">
                      {"Total XDC Locked"}
                      <br />
                      {USDTVL} {"USD"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-8 col-lg-7 col-xl-6">
            <div className="stats-box bg-white h100 p-3">
              <div
                className="media align-items-center tvl-chart-block d-flex justify-content-between"
                style={{ maxHeight: "30vh" }}
              >
                <div
                  className="chart col-xs-5  col-sm-6 col-md-5 col-lg-6 col-xl-5 max-width-100"
                  style={{ width: "14rem", height: "4rem" }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    {/* TradingView Widget BEGIN */}
                    {/* <div className="chart">
                      <img src={chartImg} alt="chart" />
                    </div> */}
                    {/* TradingView Widget END */}

                    <AreaChart
                      width={500}
                      height={400}
                      data={chartData}
                      onMouseMove={(item) => {
                        if (item.activePayload) {
                          setHoveredValue(item.activePayload[0].payload);
                        }
                      }}
                      onMouseLeave={() => {
                        setHoveredValue(undefined);
                      }}
                    >
                      <defs>
                        <linearGradient
                          id="colorPoolValue"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="12%"
                            stopColor="#3366d6"
                            stopOpacity={0.7}
                          />
                          <stop
                            offset="45%"
                            stopColor="#7e6cfb"
                            stopOpacity={0.5}
                          />
                          <stop
                            offset="95%"
                            stopColor="#71a3f7"
                            stopOpacity={0.05}
                          />
                        </linearGradient>
                      </defs>
                      {/* <XAxis type="number" dataKey="date" /> */}
                      {/* <YAxis
                        type="number"
                        domain={[0, USDTVL]}
                        dataKey="USDTVL"
                      /> */}
                      <Area
                        type="monotone"
                        dataKey="FXDSupply"
                        stroke="#3366d6"
                        strokeWidth={2}
                        fillOpacity={1}
                        fill="url(#colorPoolValue)"
                        name="Current Value Locked (USD)"
                      />
                      <Tooltip
                        content={
                          <>
                            <em>{hoveredValue ? hoveredValue.date : ""}</em>
                            <br />
                            <em>
                              FXD Supply{" "}
                              {hoveredValue
                                ? numberToReadable(hoveredValue.FXDSupply)
                                : 0}
                            </em>
                          </>
                        }
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <div className="media-body text-center tvl-gap-bet-col col-sm-6 col-md-5 col-lg-5 col-xl-5 mt-lg-0 mt-md-0 mt-2 ml-md-1.2">
                  <div className="d-flex align-items-baseline tvl-header no-wrap mb-0">
                    <div className="token-icon">
                      <img src={FXDIcon} alt="FXD" />
                    </div>
                    <div className="no-wrap">
                      <span className="fs-22">{FXDSupply}</span>
                      <span className="fs-14 fw-8">FXD</span>
                    </div>
                  </div>
                  <div className="" style={{ width: 190 }}>
                    <p className="fs-13 fc-dark fw-5">
                      {"Total FXD Supply"} <br />
                      <a
                        href="https://dapp.fathom.fi/"
                        rel="noreferrer"
                        target="_blank"
                        className="btn btn-claim"
                      >
                        Get FXD
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end container */}
    </section>
  );
};

export default TVLSection;
