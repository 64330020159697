import React from "react";
import Header from "./Header";
import NavigationBar from "./NavigationBar";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
import "../../assets/css/fonts.css";
import Footer from "./Footer";

const UserLayout = ({ children }) => {
  return (
    <>
      <Header />
      <NavigationBar />
      {children}
      <Footer />
    </>
  );
};

export default UserLayout;
