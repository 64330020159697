import React from "react";
import TVLSection from "../../components/user/TVLSection";
import PoolList from "../../components/user/PoolList";

const Home = () => {
  return (
    <>
      <TVLSection />
      <PoolList />
      <section className="section-sm pt-1 pb-0">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <p className="small lh-14 fc-dark mb-2">
                <span className="fw-8">Disclaimer</span>
              </p>
              <p className="small lh-14 fc-dark mb-1">
                TradeFinex gathers information from different sources. Make sure
                to read the disclaimer and prospectus, and only participate
                using regulated methods. Before using any third-party services,
                do your own research to make an informed decision.
              </p>
              <p className="small lh-14 fc-dark mb-1">
                <span className="fw-8">No Investment Advice: </span>The
                information provided on this website does not constitute
                investment advice, financial advice, trading advice, or any
                other sort of advice and you should not treat any of the
                website's content as such. TradeFinex does not recommend that
                any cryptocurrency should be bought, sold, or held by you. Do
                conduct your own due diligence and consult your financial
                advisor before making any investment decisions.
              </p>
              <p className="small lh-14 fc-dark mb-1">
                <span className="fw-8">Accuracy of Information: </span>
                TradeFinex will strive to ensure accuracy of information listed
                on this website although it will not hold any responsibility for
                any missing or wrong information. TradeFinex provides all
                information as is. You understand that you are using any and all
                information available here at your own risk.
              </p>
              <p className="small lh-14 fc-dark mb-1">
                <span className="fw-8">Non Endorsement: </span>The appearance of
                third party advertisements and hyperlinks on TradeFinex does not
                constitute an endorsement, guarantee, warranty, or
                recommendation by TradeFinex. Do conduct your own due diligence
                before deciding to use any third party services.
              </p>
              <p className="small lh-14 fc-dark mb-1">
                <span className="fw-8">Affiliate Disclosure: </span>TradeFinex
                may receive compensation for affiliate links. This compensation
                may be in the form of money or services and could exist without
                any action from a site visitor. Should you perform activities in
                relation to an affiliate link, it is understood that some form
                of compensation might be made to TradeFinex. For example, if you
                click on an affiliate link, and sign up and trade on an
                exchange, TradeFinex may receive compensation. Each affiliate
                link is clearly marked with an icon next to it.
              </p>
            </div>
          </div>
        </div>
      </section>
      <br />
    </>
  );
};

export default Home;
