import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  APIURL,
  axiosInstance,
  numberToReadable,
} from "../../helpers/constants";
import FXDLogo from "../../assets/images/icons/fxd-icon.jpg";
import Web3 from "web3";
import xrc20ABI from "../../wallet/TokenAbi.json";
import axios from "axios";

const Overview = () => {
  const { pool } = useParams();
  const [poolDetails, setPoolDetails] = useState({});
  const [FXDPrice, setFXDPrice] = useState(0);
  const [FXDSupply, setFXDSupply] = useState(0);

  const updateFXDPrice = async () => {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(process.env.REACT_APP_mainnetRPC)
    );
    const FXDContract = new web3.eth.Contract(
      xrc20ABI,
      process.env.REACT_APP_tokenContractAddress
    );
    const USDTContract = new web3.eth.Contract(
      xrc20ABI,
      process.env.REACT_APP_USDTTokenAddress
    );
    let bal = await FXDContract.methods
      .balanceOf(process.env.REACT_APP_tokenPairAddress)
      .call();
    const tokenBal = parseFloat(
      parseFloat(web3.utils.fromWei(bal, "ether")).toFixed(4)
    );
    bal = parseFloat(
      await USDTContract.methods
        .balanceOf(process.env.REACT_APP_tokenPairAddress)
        .call()
    );
    const usdtBal = parseFloat(parseFloat(bal / 1e6).toFixed(4));
    setFXDPrice(parseFloat(parseFloat(usdtBal / tokenBal).toFixed(4)));
    const fetchURL =
      "https://xdc.network/api/tokensinfo/xdc49d3f7543335cf38fa10889ccff10207e22110b5?type=totalsupply";
    const fetchData = await axios.get(fetchURL);
    setFXDSupply(parseFloat(parseFloat(fetchData.data).toFixed(0)));
  };

  updateFXDPrice();

  useEffect(() => {
    const fetchPoolDetails = async () => {
      const data = await axiosInstance.get(
        `/get-pool-by-name?poolName=${pool}`
      );
      setPoolDetails(data.data.data);
    };

    fetchPoolDetails();
  }, [pool]);

  return (
    <>
      {/* TVL Stats start */}
      <section className="section-sm section-padding-140-top pb-0">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="overview-head-block d-flex mb-3">
                <img
                  src={`${APIURL}/uploads/${poolDetails.logoFileName}`}
                  alt="logo"
                  className="pool-icon"
                />
                <div className="overview-head media-body gap-bet-col gap-0">
                  <div className="tophead d-flex gap-18">
                    <span className="fs-18 fw-7 fc-dark">
                      {poolDetails.poolName} ({poolDetails.poolStatus})
                    </span>
                    <span className="cap-label">
                      {numberToReadable(poolDetails.poolCapacity)}{" "}
                      {poolDetails.investmentTokenSymbol}
                    </span>
                  </div>
                  <p className="fs-16 fw-6 lh-16 fc-dark">Overview</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-1 justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="stats-box p-3 bg-white">
                <div className="row stats-counter">
                  <div className="col-sm-6 col-md-6 col-lg-3 ver-divider ver-divider_none-md safari-float-left py-2 mb-2">
                    <div className="media-body text-center tvl-gap-bet-col gap-6 align-items-center">
                      <div className="d-flex tvl-header no-wrap gap-6 mb-0">
                        <div className="no-wrap">
                          <span className="fs-16 fw-6 fc-dark">
                            {poolDetails.assetType}
                          </span>
                        </div>
                      </div>
                      <p className="fs-13 fc-light">Asset type</p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-2 ver-divider ver-divider_none-md safari-float-left py-2 mb-2">
                    <div className="media-body text-center tvl-gap-bet-col gap-6 align-items-center">
                      <div className="d-flex tvl-header no-wrap mb-0">
                        <div className="no-wrap">
                          <span className="fs-16 fw-6 fc-dark">
                            {poolDetails.tenure} months
                          </span>
                        </div>
                      </div>
                      <p className="fs-13 fc-light">Asset maturity</p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-2 ver-divider ver-divider_none-md safari-float-left py-2 mb-2">
                    <div className="media-body text-center tvl-gap-bet-col gap-6 align-items-center">
                      <div className="d-flex tvl-header no-wrap gap-6 mb-0">
                        <div className="no-wrap">
                          <span className="fs-16 fw-6 fc-dark">
                            {poolDetails.apyRate}
                          </span>
                          <span className="fs-12">%</span>
                        </div>
                      </div>
                      <p className="fs-13 fc-light">APY</p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-2 ver-divider ver-divider_none-md safari-float-left br-none py-2 mb-2">
                    <div className="media-body text-center tvl-gap-bet-col gap-6 align-items-center">
                      <div className="d-flex tvl-header no-wrap gap-6 mb-0">
                        <div className="stats-icon">
                          <img src={FXDLogo} alt="fxd-logo" />
                        </div>
                        <div className="no-wrap">
                          <span className="fs-16 fw-6 fc-dark">
                            {poolDetails.poolCapacity}
                          </span>
                          <span className="fs-12">
                            {poolDetails.investmentTokenSymbol}
                          </span>
                        </div>
                      </div>
                      <p className="fs-13 fc-light">Total Pool value</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-3 ver-divider ver-divider_none-md safari-float-left py-2 mb-2">
                    <div className="media-body text-center tvl-gap-bet-col gap-6 align-items-center">
                      <div className="d-flex tvl-header no-wrap gap-6 mb-0">
                        <div className="no-wrap">
                          <span className="fs-16 fw-6 fc-dark">
                            {poolDetails.investmentTokenName} -{" "}
                            <span className="fs-16 fw-8">
                              {poolDetails.investmentTokenSymbol}
                            </span>
                          </span>
                        </div>
                      </div>
                      <p className="fs-13 fc-light">Investment Token </p>
                    </div>
                  </div>
                </div>
                {/* end row */}
              </div>
            </div>
          </div>
        </div>
        {/* end container */}
      </section>
      {/* TVL Stats end */}
      {/* Table start */}
      <section className="section-sm pb-4">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="overview-head-block d-flex mb-2">
                <h3 className="fs-18 fw-7 lh-20 fc-dark">
                  Asset Originator Details
                </h3>
              </div>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="stats-box p-3 light-border-white-bg">
                <div className="ao-details gap-24 d-flex">
                  <div className="ao-logo d-flex">
                    <img
                      src={`${APIURL}/uploads/${poolDetails.logoFileName}`}
                      alt="logo"
                      className="pr-2"
                    />
                    <h3 className="fw-6" style={{ margin: "10px 0" }}>
                      {poolDetails.poolName} ({poolDetails.poolStatus})
                    </h3>
                  </div>
                  <p className="fs-14 fc-dark mb-0">
                    {poolDetails.description}
                  </p>
                  <div className="ao-footer gap-20">
                    <div className="issuer d-flex">
                      <p className="fs-13 fc-light mb-0">Issuer</p>
                      <div className="issuer-name fs-14 fw-6 fc-dark">
                        {poolDetails.issuerName}
                      </div>
                    </div>
                    <div className="link d-flex">
                      <p className="fs-13 fc-light mb-0">Links</p>
                      <div className="link-name d-flex fs-14 fw-6 fc-dark">
                        <a
                          href="https://comtechgold.com/assets/whitepaper/cgo_whitepaper_v1.pdf"
                          target="_blank"
                          rel="noreferrer"
                          style={{ pointerEvents: "none" }}
                        >
                          <i className="fa fa-book-open" /> White Paper
                        </a>
                        <a
                          href={poolDetails.website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-globe" /> Website
                        </a>
                        <a href={`mailto:${poolDetails.issuerEmail}`}>
                          <i className="fa fa-envelope" /> Contact the Issuer
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="overview-head-block d-flex mb-0">
                <h3 className="fs-18 fw-7 lh-20 fc-dark">Status</h3>
              </div>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="stats-box p-3 bg-white h100">
                <h3 className="fs-16 fw-7 lh-20 fc-dark mt-0 mb-3">
                  Asset Details
                </h3>
                <div className="ao-details gap-12 d-flex mb-0">
                  <div className="fc-dark d-flex justify-content-between">
                    <span className>Token Price</span>
                    <span className>{FXDPrice} USD</span>
                  </div>
                  <hr className="hr-space" />
                  <div className="fc-dark d-flex justify-content-between">
                    <span className>Total Supply</span>
                    <span className>
                      {numberToReadable(
                        poolDetails.simpleName === "yieldteq"
                          ? poolDetails.poolCapacity
                          : FXDSupply
                      )}
                    </span>
                  </div>
                  <hr className="hr-space" />
                  <div className="fc-dark d-flex justify-content-between">
                    <span className>Total Pool Value</span>
                    <span className>
                      {numberToReadable(poolDetails.poolCapacity)}
                    </span>
                  </div>
                  <hr className="hr-space" />
                  <div className="fc-dark d-flex justify-content-between">
                    <span className>Blockchain Network</span>
                    <span className>XDC Network</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="h100 stats-box bg-white mt-4 mt-md-0 mt-lg-0">
                <div className=" p-3 ">
                  <h3 className="d-flex gap-8 fs-16 fw-7 lh-20 fc-dark mt-0 mb-3">
                    Reward Details
                  </h3>
                  {/*<div class="d-flex justify-content-between mb-3">
                                    <div class="d-flex tvl-header no-wrap gap-8 mb-0">
                                        <div class="stats-icon d-none d-sm-block"><img src="assets/images/pool-assets/drop-icon.svg" class="" /></div>
                                        <div class="no-wrap lh-18">
                                            <span class="fs-16 fw-7 fc-dark">Reward Details</span>
                                        </div>
                                    </div>
                                </div>*/}
                  <div className="ao-details gap-12 d-flex">
                    <div className="d-flex justify-content-between">
                      <span className>{poolDetails.investmentTokenSymbol}</span>
                      <div className="d-flex gap-4">
                        <span className>{poolDetails.apyRate}</span>
                        <span>% p.a.</span>
                      </div>
                    </div>
                    <hr className="hr-space" />
                    <div className="d-flex justify-content-between">
                      <span className>XDC</span>
                      <div className="d-flex gap-4">
                        <span className>{poolDetails.rewardRate}</span>
                        <span>% p.a.</span>
                      </div>
                    </div>
                    <hr className="hr-space" />

                    <div className="fw-7 d-flex justify-content-between">
                      <span className>Total Rewards</span>
                      <div className="d-flex gap-4">
                        <span className>
                          {poolDetails.apyRate + poolDetails.rewardRate}
                        </span>
                        <span>% p.a.</span>
                      </div>
                    </div>
                    <hr className="hr-space" />
                    <div className="d-flex justify-content-between">
                      <span className>Maturity Cycle</span>
                      <div className="d-flex gap-4">
                        <span className>{poolDetails.settlementCycle}</span>
                        <span>Days</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end container */}
      </section>
      {/* Table End */}
    </>
  );
};

export default Overview;
