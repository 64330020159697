import React from "react";
import LogoDark from "../../assets/images/logo-dark.png";
import LogoLight from "../../assets/images/logo-dark.png";
import { Link } from "react-router-dom";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";

const NavigationBar = () => {
  return (
    <header>
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        className="navbar-custom nav-sticky"
      >
        <div
          className="mx-4 d-lg-flex justify-content-lg-start"
          style={{ width: "100%" }}
        >
          <div className="d-flex justify-content-sm-between justify-content-between">
            <Link className="logo text-uppercase" to="/">
              <img src={LogoLight} alt="" className="logo-light" />
              <img src={LogoDark} alt="" className="logo-dark" />
            </Link>
            <Navbar.Toggle aria-controls="navbarSupportedContent" />
          </div>
          <Navbar.Collapse id="navbarSupportedContent">
            <div className="tf-nav-inner">
              <Nav id="mySideNav" as="ul" className="ml-4">
                {/* <NavDropdown
                  title="Originator"
                  renderMenuOnMount={true}
                  as="li"
                >
                  <NavDropdown.Item
                    className="nav-link outline-none border-none"
                    href="/add-pool"
                  > */}
                <Nav.Link className="nav-item mt-1 ml-3" as="li">
                  <Link to="/add-pool">Register Pool</Link>
                </Nav.Link>
                {/* </NavDropdown.Item> */}
                {/* <NavDropdown.Item
                  className="nav-link outline-none border-none"
                  href="/add-token"
                >
                  Register Token
                </NavDropdown.Item> */}
                {/* </NavDropdown> */}
                <Nav.Link className="nav-item mt-1 ml-3" as="li">
                  <Link to="/staking-guide">Staking Guide</Link>
                </Nav.Link>
                <Nav.Link className="nav-item mt-1 ml-3" as="li">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdyQkwpYPAAUc5llJxk09ymMdjSSSjjiY3spwvRvCwfV08h2A/viewform"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Apply to Whitelist
                  </a>
                </Nav.Link>
                <Nav.Link className="nav-item mt-1 ml-3" as="li">
                  <span className="disclaimer cap-label px-2 py-1 mr-1 ">
                    <i
                      className="fa fa-exclamation-circle mr-1"
                      aria-hidden="true"
                    ></i>
                    <Link to="/disclaimer">Disclaimer</Link>
                  </span>
                </Nav.Link>
              </Nav>
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};

export default NavigationBar;
