import React from "react";

const Disclaimer = () => {
  return (
    <section className="section-sm section-layout-height section-padding-10-top pb-0">
      <div className="container bg-red custom-container">
        <div className="section-padding-120-top">
          <div>
            <h5 style={{ paddingBottom: 10 }}>Disclaimer</h5>
            <p>
              TradeFinex gathers information from different sources. Make sure
              to read the disclaimer and prospectus, and only participate using
              regulated methods. Before using any third-party services, do your
              own research to make an informed decision.
            </p>{" "}
            <h6 style={{ fontWeight: "bold" }}>No Investment Advice</h6>{" "}
            <p>
              The information provided on this website does not constitute
              investment advice, financial advice, trading advice, or any other
              sort of advice and you should not treat any of the website's
              content as such. TradeFinex does not recommend that any
              cryptocurrency should be bought, sold, or held by you. Do conduct
              your own due diligence and consult your financial advisor before
              making any investment decisions.
            </p>{" "}
            <h6 style={{ fontWeight: "bold" }}>Accuracy of Information</h6>
            <p>
              {" "}
              TradeFinex will strive to ensure accuracy of information listed on
              this website although it will not hold any responsibility for any
              missing or wrong information. TradeFinex provides all information
              as is. You understand that you are using any and all information
              available here at your own risk.
            </p>{" "}
            <h6 style={{ fontWeight: "bold" }}>Non Endorsement</h6>
            <p>
              The appearance of third party advertisements and hyperlinks on
              TradeFinex does not constitute an endorsement, guarantee,
              warranty, or recommendation by TradeFinex. Do conduct your own due
              diligence before deciding to use any third party services.
            </p>{" "}
            <h6 style={{ fontWeight: "bold" }}>Affiliate Disclosure</h6>
            <p>
              TradeFinex may receive compensation for affiliate links. This
              compensation may be in the form of money or services and could
              exist without any action from a site visitor. Should you perform
              activities in relation to an affiliate link, it is understood that
              some form of compensation might be made to TradeFinex. For
              example, if you click on an affiliate link, and sign up and trade
              on an exchange, TradeFinex may receive compensation. Each
              affiliate link is clearly marked with an icon next to it.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Disclaimer;
